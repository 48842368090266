import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

Pusher.logToConsole = true;
Pusher.log = function(msg) {
    console.log(msg);
  };
  
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: process.env.MIX_PUSHER_APP_HOST,
    wssHost: process.env.MIX_PUSHER_APP_HOST,
    wsPort: process.env.MIX_PUSHER_APP_PORT,
    wssPort: process.env.MIX_PUSHER_APP_PORT,
    forceTLS: false,
    enableStats: false,
    enabledTransports: ['ws', 'wss'],
    encrypted: true,
});