$ = global.$ = global.jQuery = window.$ = require('jquery');
require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-de.js');
//require('jquery-ui-touch-punch');
//require('jquery-form');
//require('jquery-validation');
//require('jquery-jeditable');

