
require('./bootstrap');
require('./broadcast');


XLSX = require('xlsx');
require('./jquery');

require('jqGrid/js/jquery.jqGrid.js');
require('jqGrid/js/grid.base.js');
require('jqGrid/js/grid.celledit.js');
require('jqGrid/js/grid.common.js');
require('jqGrid/js/grid.filter.js');
require('jqGrid/js/grid.formedit.js');
require('jqGrid/js/grid.grouping.js');
require('jqGrid/js/grid.import.js');
require('jqGrid/js/grid.inlinedit.js');
require('jqGrid/js/grid.jqueryui.js');
require('jqGrid/js/grid.pivot.js');
require('jqGrid/js/grid.subgrid.js');
require('jqGrid/js/grid.treegrid.js');
require('jqGrid/js/jqDnR.js');
require('jqGrid/js/jqModal.js');
require('jqGrid/js/jquery.fmatter.js');
require('jqGrid/js/addons/ui.multiselect');
require('jqGrid/js/grid.aria.js');
require('jqGrid/js/grid.transposed.js');
require('jqGrid/js/grid.frozenrows.js');
require('jqGrid/js/i18n/grid.locale-en.js');
//require('kassaline-free-jqgrid/plugins/min/ui.multiselect');
//require('kassaline-free-jqgrid/js/i18n/grid.locale-de');

require('js-throttle-debounce');
require('jquery.easing');
require('./smartpanels');
//require('./app.bundle.js')

require('datatables.net-autofill-bs4');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-colreorder-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('datatables.net-rowreorder-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-select-bs4');
require('datatables.net-alteditor-updated');

window.toastr = toastr = require('toastr');
require('select2');
require('flot');
require('flot/jquery.flot');
require('flot/jquery.flot.canvas');
require('flot/jquery.flot.categories');
require('flot/jquery.flot.crosshair');
require('flot/jquery.flot.errorbars');
require('flot/jquery.flot.fillbetween');
require('flot/jquery.flot.image');
require('flot/jquery.flot.navigate');
require('flot/jquery.flot.pie');
require('flot/jquery.flot.resize');
require('flot/jquery.flot.selection');
require('flot/jquery.flot.stack');
require('flot/jquery.flot.symbol');
require('flot/jquery.flot.threshold');
require('flot/jquery.flot.time');
//require('flot/jquery.pie');